import React from 'react'
import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'

import { Container, Row, Column } from '@/components/base/gridview'
import Image from '@/components/base/image/Image'

import { Price, PriceProps } from './type'

const Price: React.FunctionComponent<PriceProps> = ({ title, data }) => {
  let formattedPrice: string
  const app = useApp()

  const PriceFormatter: React.FunctionComponent<Price> = ({
    amount,
    currency,
  }) => {
    if (currency === 'try') {
      formattedPrice = `${amount} ₺`
    } else if (currency === 'usd') {
      formattedPrice = `${amount} $`
    } else if (currency === 'euro') {
      formattedPrice = `${amount} €`
    } else {
      formattedPrice = `${amount} ${currency}`
    }
    return <span>{formattedPrice}</span>
  }

  return (
    <div className={classnames('price section')}>
      <Container size="extended">
        <Row xs={{ align: 'center' }}>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <div className="section-title text-center">{title}</div>
          </Column>
        </Row>
        <Row>
          {data?.map((item, index) => (
            <Column
              xs={{ size: 12 }}
              sm={{ size: 12 }}
              md={{ size: 12 }}
              lg={{ size: 6 }}
              xl={{ size: 3 }}
              className="price-list"
              key={index}
            >
              <div className="price-list-item">
                <div className="desc">
                  <figure>
                    <Image {...item.icon} alt={item.title} />
                  </figure>
                  <div className="title">
                    {item.title}{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: app.settings.translations['charge'],
                      }}
                    />
                  </div>
                </div>
                <div className="price">
                  <div className="price-d">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: app.settings.translations['price'],
                      }}
                    />{' '}
                    <PriceFormatter
                      amount={item.price.amount}
                      currency={item.price.currency}
                    />
                  </div>
                </div>
              </div>
            </Column>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Price
